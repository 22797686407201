import Vue from 'vue'
import {
    extend,
    localize,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate'
import {
    email,
    max,
    min,
    min_value,
    numeric,
    required,
    length
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';

extend('email', email)
extend('max', max)
extend('min', min)
extend('min_value', min_value)
extend('length', length)
extend('numeric', numeric)
extend('required', required)

localize({
    en,
    pt_BR
})
localize(process.env.VUE_APP_I18N_LOCALE || 'pt_BR')


Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)