import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import setModule from '@/helpers/setModule.js'

const instance = createInstance()

function createInstance() {
    return axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

instance.interceptors.request.use((request) => {
    if (store.state.auth.token) {
        request.headers.common.Authorization = `Bearer ${store.state.auth.token}`
    }

    let dataDefault = {
        language: store.state.system.language === 'pt_BR' ? 'pt' : store.state.system.language,
        clientId: store.state.system.clientId,
        system: store.state.system.system,
    }

    let module = setModule(request, store.state.system.module)

    if (request.data instanceof FormData) {
        request.headers.common['Content-Type'] = 'multipart/form-data'

        request.data.append('language', dataDefault.language)
        request.data.append('clientId', dataDefault.clientId)
        request.data.append('module', module)
        request.data.append('system', dataDefault.system)
    } else {
        request.data = {
            ...dataDefault,
            ...request.data,
            module
        }
    }

    store.commit('issue/setLastRequest', request)
    return request
}, (error) => {
    return Promise.reject(error)
})

// instance.interceptors.response.use((response) => {
//     if (response && response.data && response.data.data) {
//         response.data = response.data.data
//     }

//     return response
// }, (err) => {
//     console.log(err)
//     if (err.status === 500) {
//         this.$toast.error('Erro inesperado no servidor')
//     } else {
//         this.$toast.error(err.data.msg)
//     }
    
    // if (error.response.data.cod == 'MSG0012'){
    //     let url = null

    //     store.dispatch('logout')
    //     console.log('axios reset state interceptor')

    //     if (window.location.href.indexOf(':80') == -1)	{
    //         url = 'https://znap.znaptech.com'
    //     } else {
    //         url = 'http://znap.znaptech:8080'
    //     }
        
    //     window.location.href = url
    //     return Promise.reject(error.response)
    // } else {
    //     return Promise.reject(error.response)
    // }
// })

Vue.prototype.$http = instance

export default {
    getInstance: () => instance
}