export default [

    // USER PROFILE
    {
        name: 'UserProfile',
        path: '/user-profile',
        component: () =>
            import('@/views/UserProfile'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // APPROVAL FLOW
    {
        name: 'ApprovalFlow',
        path: '/approval-flow',
        component: () =>
            import('@/views/ApprovalFlow'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowLevel',
        path: '/approval-flow-level',
        component: () =>
            import('@/views/ApprovalFlowLevel'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowTransaction',
        path: '/approval-flow-transaction',
        component: () =>
            import('@/views/ApprovalFlowTransaction'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ApprovalFlowPlanningGroup',
        path: '/approval-flow-planning-group',
        component: () =>
            import('@/views/ApprovalFlowPlanningGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // ACCOUNT
    {
        name: 'ChartAccountGroup',
        path: '/chart-account-group',
        component: () =>
            import('@/views/ChartAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountValue',
        path: '/chart-account-value',
        component: () =>
            import('@/views/ChartAccountValue'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'Invoicing',
        path: '/invoicing',
        component: () =>
            import('@/views/Invoicing'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountValueType',
        path: '/chart-account-value-type',
        component: () =>
            import('@/views/ChartAccountValueType'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ChartAccountGroupVersion',
        path: '/chart-account-group-version',
        component: () =>
            import('@/views/ChartAccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroup',
        path: '/account-group',
        component: () =>
            import('@/views/AccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupCalc',
        path: '/account-group-calc',
        component: () =>
            import('@/views/AccountGroupCalc'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupVersion',
        path: '/account-group-version',
        component: () =>
            import('@/views/AccountGroupVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupReference',
        path: '/account-group-reference',
        component: () =>
            import('@/views/AccountGroupReference'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupSystemId',
        path: '/account-group-system-id',
        component: () =>
            import('@/views/AccountGroupSystemId'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilterVersion',
        path: '/account-group-filter-version',
        component: () =>
            import('@/views/AccountGroupFilterVersion'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountGroupFilter',
        path: '/account-group-filter',
        component: () =>
            import('@/views/AccountGroupFilter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // EVENT
    {
        name: 'Ratio',
        path: '/ratio',
        component: () =>
            import('@/views/Ratio'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'RatioAccount',
        path: '/ratio-account',
        component: () =>
            import('@/views/RatioAccount'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'Consolidation',
        path: '/consolidation',
        component: () =>
            import('@/views/Consolidation'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PlConsolidationStatus',
        path: '/pl-consolidation-status',
        component: () =>
            import('@/views/PlConsolidationStatus'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ImportConsolidationKey',
        path: '/import-consolidation-key',
        component: () =>
            import('@/views/ImportConsolidationKey'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'Event',
        path: '/event',
        component: () =>
            import('@/views/Event'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventCreationAssistant',
        path: '/event-creation-assistant',
        component: () =>
            import('@/views/EventCreationAssistant'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfig',
        path: '/event-config',
        component: () =>
            import('@/views/EventConfig'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventConfigAccountGroup',
        path: '/event-config-account-group',
        component: () =>
            import('@/views/EventConfigAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventCalendar',
        path: '/event-calendar',
        component: () =>
            import('@/views/EventCalendar'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'EventStatusByUser',
        path: '/event-status-by-user',
        component: () =>
            import('@/views/EventStatusByUser'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // CONSOLIDATION KEY
    {
        name: 'ConsolidationKey',
        path: '/consolidation-key',
        component: () =>
            import('@/views/ConsolidationKey'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ConsolidationKeyType',
        path: '/consolidation-key-type',
        component: () =>
            import('@/views/ConsolidationKeyType'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // ACCOUNTING CLOSING
    {
        name: 'AccountingClosingType',
        path: '/accounting-closing-type',
        component: () =>
            import('@/views/AccountingClosingType'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosingAccountGroup',
        path: '/accounting-closing-account-group',
        component: () =>
            import('@/views/AccountingClosingAccountGroup'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosing',
        path: '/accounting-closing',
        component: () =>
            import('@/views/AccountingClosing'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosingCostCenter',
        path: '/accounting-closing-cost-center',
        component: () =>
            import('@/views/AccountingClosingCostCenter'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosingEvent',
        path: '/accounting-closing-event',
        component: () =>
            import('@/views/AccountingClosingEvent'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosingCsv',
        path: '/accounting-closing-csv',
        component: () =>
            import('@/views/AccountingClosingCsv'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'AccountingClosingReport',
        path: '/accounting-closing-report',
        component: () =>
            import('@/views/AccountingClosingReport'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },

    // PL PLANNING
    {
        name: 'PlPlanning',
        path: '/pl-planning',
        component: () =>
            import('@/views/PlPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'DetailedPlPlanning',
        path: '/detailed-pl-planning',
        component: () =>
            import('@/views/DetailedPlPlanning'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'ReportGallery',
        path: '/report-gallery',
        component: () =>
            import('@/views/ReportGallery'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
    {
        name: 'PlReport',
        path: '/pl-report',
        component: () =>
            import('@/views/PlReport'),
            meta: {
                requireAuth: true,
                redirectHomeIfLogged: false
            }
    },
]