"use strict";

import pt from 'vuetify/es5/locale/pt'

export default {
    ...pt,

    // USER PROFILE
    name: 'Nome',
    password: 'Senha',
    changePassword: 'Alterar senha',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    cancel: 'Cancelar',
    userInformation: 'Informações do usuário',
    userInformationxs: 'Perfil',
    change: 'Alterar',
    add: 'Adicionar',
    remove: 'Remover',
    primaryEmail: 'E-mail principal',
    addAlternateEmail: 'Adicionar e-mail alternativo',
    addAlternateEmailxs: 'E-mail alternativo',
    phone: 'Telefone',
    primaryPhone: 'Telefone principal',
    addAlternatePhone: 'Adicionar telefone alternativo',
    addAlternatePhonexs: 'Tel alternativo',
    extension: 'Ramal',
    smsCellphone: 'Preencha um celular para receber SMS',
    smsCellphonexs: 'Celular para SMS',
    chooseRegisteredPhone: 'Ou escolha um telefone cadastrado',
    passwordExpirationDate: 'Data de expiração da senha',
    lastUpdate: 'Última atualização',
    saveChanges: 'Salvar alterações',
    save: 'Salvar',

    // TABLE
    export: 'Exportar planilha',
    import: 'Importar planilha',
    searchLabel: 'Pesquisar',
    reload: 'Recarregar',
    massDeleteBtn: 'Deletar itens selecionados',
    crudCreateBtn: 'Criar',
    crudDeleteBtn: 'Deletar',
    crudUpdateBtn: 'Editar',
    editingTableSnack: 'Editando linha da tabela',
    cancelTableSnack: 'Edição cancelada',
    saveTableSnack: 'Edição salva',
    toastError: 'Ops...!',
    toastSuccess: 'Êxito!',
    deleteSure: 'Você tem certeza que deseja deletar este item?',
    client: {
        title: 'Clientes',
        dialog: {
            create: 'Criar cliente',
            delete: 'Deletar cliente',
            massDelete: 'Você tem certeza que deseja excluir estes clientes?',
            update: 'Atualizar cliente',
        }
    },
    module: {
        title: 'Módulos',
        dialog: {
            create: 'Criar módulos',
            delete: 'Deletar módulo',
            massDelete: 'Você tem certeza que deseja excluir estes módulos?',
            update: 'Atualizar módulo',
        }
    },

    // APPROVAL FLOW
    'approval-flow': {
        title: 'Fluxo de aprovação',
        dialog: {
            create: 'Criar fluxo de aprovação',
            delete: 'Deletar fluxo de aprovação',
            massDelete: 'Você tem certeza que deseja excluir estes fluxos de aprovação?',
            update: 'Atualizar fluxo de aprovação',
        }
    },
    'approval-flow-level': {
        title: 'Nível do fluxo de aprovação',
        dialog: {
            create: 'Criar nível do fluxo de aprovação',
            delete: 'Deletar nível do fluxo de aprovação',
            massDelete: 'Você tem certeza que deseja excluir estes níveis do fluxo de aprovação?',
            update: 'Atualizar nível do fluxo de aprovação',
        }
    },
    'approval-flow-transaction': {
        title: 'Transação do fluxo de aprovação',
        dialog: {
            create: 'Criar transação do fluxo de aprovação',
            delete: 'Deletar transação do fluxo de aprovação',
            massDelete: 'Você tem certeza que deseja excluir estas transações do fluxo de aprovação?',
            update: 'Atualizar transação do fluxo de aprovação',
        }
    },
    'approval-flow-cost-center': {
        title: 'Centro de custo do fluxo de aprovação',
        dialog: {
            create: 'Criar centro de custo do fluxo de aprovação',
            delete: 'Deletar centro de custo do fluxo de aprovação',
            massDelete: 'Você tem certeza que deseja excluir estes centros de custo do fluxo de aprovação?',
            update: 'Atualizar centro de custo do fluxo de aprovação',
        }
    },
    'planning-group-version': {
        title: 'Versão do grupo de planejamento',
        dialog: {
            create: 'Criar versão do grupo de planejamento',
            delete: 'Deletar versão do grupo de planejamento',
            massDelete: 'Você tem certeza que deseja excluir estas versões do grupo de planejamento?',
            update: 'Atualizar versão do grupo de planejamento',
        }
    },
    'planning-group': {
        title: 'Grupo de planejamento',
        dialog: {
            create: 'Criar grupo de planejamento',
            delete: 'Deletar grupo de planejamento',
            massDelete: 'Você tem certeza que deseja excluir estes grupos de planejamento?',
            update: 'Atualizar grupo de planejamento',
        }
    },
    'approval-flow-planning-group': {
        title: 'Fluxo de aprovação do grupo de planejamento',
        dialog: {
            create: 'Criar fluxo de aprovação do grupo de planejamento',
            delete: 'Deletar fluxo de aprovação do grupo de planejamento',
            massDelete: 'Você tem certeza que deseja excluir estes fluxos de aprovação do grupo de planejamento?',
            update: 'Atualizar fluxo de aprovação do grupo de planejamento',
        }
    },
    'event-status-planning-group': {
        title: 'Status do evento do grupo de planejamento',
        dialog: {
            create: 'Criar status do evento do grupo de planejamento',
            delete: 'Deletar status do evento do grupo de planejamento',
            massDelete: 'Você tem certeza que deseja excluir estes status do evento do grupo de planejamento?',
            update: 'Atualizar status do evento do grupo de planejamento',
        }
    },

    // ACCOUNT
    'chart-account-group': {
        title: 'Grupo de plano de contas',
        dialog: {
            create: 'Criar grupo de plano de contas',
            delete: 'Deletar grupo de plano de contas',
            massDelete: 'Você tem certeza que deseja excluir estes grupos de plano de contas?',
            update: 'Atualizar grupo de plano de contas',
        }
    },
    'account-group': {
        title: 'Grupo de contas',
        dialog: {
            create: 'Criar grupo de contas',
            delete: 'Deletar grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estes grupos de contas?',
            update: 'Atualizar grupo de contas',
        }
    },
    'account-type': {
        title: 'Tipo de conta',
        dialog: {
            create: 'Criar tipo de conta',
            delete: 'Deletar tipo de conta',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de contas?',
            update: 'Atualizar tipo de conta',
        }
    },
    'account-group-calc': {
        title: 'Cálculo do grupo de contas',
        dialog: {
            create: 'Criar cálculo do grupo de contas',
            delete: 'Deletar cálculo do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estes cálculos do grupo de contas?',
            update: 'Atualizar cálculo do grupo de contas',
        }
    },
    'account-data-type': {
        title: 'Tipo de dado da conta',
        dialog: {
            create: 'Criar tipo de dado da conta',
            delete: 'Deletar tipo de dado da conta',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de dado da conta?',
            update: 'Atualizar tipo de dado da conta',
        }
    },
    'account-group-version': {
        title: 'Versão do grupo de contas',
        dialog: {
            create: 'Criar versão do grupo de contas',
            delete: 'Deletar versão do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estas versões do grupo de contas?',
            update: 'Atualizar versão do grupo de contas',
        }
    },
    'account-group-domain': {
        title: 'Domínio do grupo de contas',
        dialog: {
            create: 'Criar domínio do grupo de contas',
            delete: 'Deletar domínio do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estes domínios do grupo de contas?',
            update: 'Atualizar domínio do grupo de contas',
        }
    },
    'account-group-domain-item': {
        title: 'Item do domínio do grupo de contas',
        dialog: {
            create: 'Criar item do domínio do grupo de contas',
            delete: 'Deletar item do domínio do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estes itens do domínio do grupo de contas?',
            update: 'Atualizar item do domínio do grupo de contas',
        }
    },
    'account-group-reference': {
        title: 'Referência do grupo de contas',
        dialog: {
            create: 'Criar referência do grupo de contas',
            delete: 'Deletar referência do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estas referências do grupo de contas?',
            update: 'Atualizar referência do grupo de contas',
        }
    },
    'account-group-ref-key': {
        title: 'Chave de referência do grupo de contas',
        dialog: {
            create: 'Criar chave de referência do grupo de contas',
            delete: 'Deletar chave de referência do grupo de contas',
            massDelete: 'Você tem certeza que deseja excluir estas chaves de referência do grupo de contas?',
            update: 'Atualizar chave de referência do grupo de contas',
        }
    },
    'group-key': {
        title: 'Chave do grupo',
        dialog: {
            create: 'Criar chave do grupo',
            delete: 'Deletar chave do grupo',
            massDelete: 'Você tem certeza que deseja excluir estas chaves do grupo?',
            update: 'Atualizar chave do grupo',
        }
    },
    'account-group-system-id': {
        title: 'ID do sistema do grupo de contas',
        dialog: {
            create: 'Criar ID do sistema do grupo de contas',
            delete: 'Deletar ID do sistema do grupo de contas',
            massDelete: "Você tem certeza que deseja excluir estes ID's do sistema do grupo de contas?",
            update: 'Atualizar ID do sistema do grupo de contas',
        }
    },

    // EVENT
    'event-type': {
        title: 'Tipo do evento',
        dialog: {
            create: 'Criar tipo do evento',
            delete: 'Deletar tipo do evento',
            massDelete: 'Você tem certeza que deseja excluir estes tipos de evento?',
            update: 'Atualizar tipo do evento',
        }
    },
    'event': {
        title: 'Evento',
        dialog: {
            create: 'Criar evento',
            delete: 'Deletar evento',
            massDelete: 'Você tem certeza que deseja excluir estes eventos?',
            update: 'Atualizar evento',
        }
    },
    
    // SALES-PLANNING
    'sales-planning': {
        title: 'Planejamento de vendas',
        dialog: {
            create: 'Criar planejamento de vendas',
            delete: 'Deletar planejamento de vendas',
            massDelete: 'Você tem certeza que deseja excluir estes planejamentos de vendas?',
            update: 'Atualizar planejamento de vendas',
        }
    },
    'sales-planning-customer': {
        title: 'Planejamento de vendas por cliente',
        dialog: {
            create: 'Criar planejamento de vendas por cliente',
            delete: 'Deletar planejamento de vendas por cliente',
            massDelete: 'Você tem certeza que deseja excluir estes planejamentos de vendas por cliente?',
            update: 'Atualizar planejamento de vendas por cliente',
        }
    },
};